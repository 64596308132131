import React from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import batteryOk from "../../images/battery-Ok.svg"
import batteryCritical from "../../images/battery-Critical.svg"
import batteryLow from "../../images/battery-Low.svg"
import wifiOnline from "../../images/wifi_Online.svg"
import wifiOffline from "../../images/wifi_Offline.svg"

const style = { display: "flex", flexFlow: "row wrap", justifyContent: "space-around" }
const fixedWidth1 = {
    width: "50%",
    color: "#444444",
    opacity: "0.8",
    fontWeight: '700',
    fontSize: "13px",
    lineHeight: "250%"
}

const fixedWidth2 = {
    width: "50%",
    color: "#444444",
    fontWeight: '400',
    fontSize: "13px",
    lineHeight: "250%"
}


class Info extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            batteryLevel: "",
            lastConnected: "",
            connectionStatus: ""
        }
    }


    checkBattery = (data) => {
        if (data) {
            var voltage = parseFloat(data.device_battery);
            if (voltage < 2.6) {
                this.setState({
                    batteryLevel: 'Critical',
                    batteryImage: batteryCritical,
                    batteryColor: "#F65162"
                })
            } else if (voltage < 3) {
                this.setState({
                    batteryLevel: 'Low',
                    batteryImage: batteryLow,
                    batteryColor: "#FF9777"
                })
            } else {
                this.setState({
                    batteryLevel: 'Ok',
                    batteryImage: batteryOk,
                    batteryColor: "#4ECB71"
                })
            }
        }
        else {
            this.setState({
                batteryLevel: '--',
                batteryImage: null,
                batteryColor: null
            })
        }
    }

    lastConnect = (data) => {
        if (this.props.lastUpdate) {
            const momen = moment().tz(this.props.data.timings_time_zone).subtract(30, 'minutes');    //time before 30min
            // const curr = moment.unix(parseInt(this.props.data.device_time)).tz(this.props.data.timings_time_zone);   //time sent by device and converted using timezone

            // console.log("time sent by device",curr.format(("dddd, MMMM Do YYYY, h:mm:ss a")),"\ncurrent local time 30 min ago",momen.format(("dddd, MMMM Do YYYY, h:mm:ss a")))
            if (this.props.lastUpdate >= momen) {
                this.setState({
                    connectionStatus: "Online",
                    wifiImage: wifiOnline,
                    wifiColor: "#4ECB71"
                })
                this.checkBattery(data)
            }
            else {
                this.setState({
                    connectionStatus: "Offline",
                    wifiImage: wifiOffline,
                    wifiColor: "#BBBBBB"
                })
                this.checkBattery()
            }
        }

    }


    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.lastConnect(this.props.data)
        }
    }

    render() {
        return (<>
            <div className='col' style={{ display: "flex", flexDirection: "column", width: "100%", color: "#444444", opacity: "0.8", fontWeight: '700', fontSize: "13px", lineHeight: "250%" }}>

                <div style={style}><span style={fixedWidth1}>Device ID:</span><span style={fixedWidth2}>{this.props.data ? this.props.data.bless_id : ""}</span></div>
                <div style={style}><span style={fixedWidth1}>Device Status:</span>
                    <span style={{ ...fixedWidth2, color: this.state.wifiColor }}>
                        {this.state.connectionStatus ? <><img src={this.state.wifiImage} alt="wifiStrength" style={{ verticalAlign: "sub" }}></img>&nbsp;</> : ""}{this.state.connectionStatus}
                    </span>
                </div>
                <div style={style}><span style={fixedWidth1}>Last Connected:</span><span style={fixedWidth2}>{this.props.lastUpdate ? this.props.lastUpdate.format('DD MMM YYYY, HH:mm (Z)') : ""}</span></div>
                <div style={style}><span style={fixedWidth1}>Battery Level:</span>
                    <span style={{ ...fixedWidth2, color: this.state.batteryColor }}>
                        {this.state.batteryLevel ? <><img src={this.state.batteryImage} style={{ verticalAlign: "sub" }}></img>&nbsp;</> : ""}{this.state.batteryLevel}
                    </span>
                </div>
                <div style={style}><span style={fixedWidth1}>Space ID:</span> <span style={fixedWidth2}>{this.props.data ? this.props.data.space_id : ""}</span></div>
                <div style={style}><span style={fixedWidth1}>Space category:</span> <span style={fixedWidth2}>{this.props.data ? "Wall" : ""}</span></div>
                <div style={style}><span style={fixedWidth1}>Department:</span> <span style={fixedWidth2}>{this.props.data ? this.props.data.department_name : ""}</span></div>
                <div style={style}> <span style={fixedWidth1}>Floor:</span> <span style={fixedWidth2}>{this.props.data ? this.props.data.floor_name : ""}</span></div>
                <div style={style}><span style={fixedWidth1}>Location</span> <span style={fixedWidth2}>{this.props.data ? this.props.data.building_name : ""}</span></div>
            </div>
        </>);
    }
}



export default Info;