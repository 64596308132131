import React from "react";
import App1 from "./App1";

class App extends React.Component {
    
    render() { 
        return (<App1></App1>);
    }
}
 
export default App;