import React from 'react';
import freespaceIcon from "../../images/Freespace.png"

const footerStyle ={
  Weight: "700, Bold",
  style: "normal",
  fontSize: "12px",
  align: "Center",
  margin:"20px 0",
  display:"flex",
  justifyContent:"center",
  flexDirection: "column",
  alignItems: "center"
}
class Footer extends React.Component {

  render() {
    return (<footer className="main-footer" style={footerStyle}>
      <span>Copyright &copy; {new Date().getFullYear()} <a style={{ 'color': '#202153' }} href="http://www.afreespace.com/"><img src={freespaceIcon} alt="freespace" height="12px"></img></a></span>
      <span>All rights reserved.</span>
    </footer>);
  }
}

export default Footer;