import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import am4themes_material from "@amcharts/amcharts4/themes/dataviz";


class LineChart extends React.Component {
    constructor(props) {
        super(props);
        this.setContainerRef = element => {
            this.containerRef = element;
        };
    }
    prepareChart = () => {
        
        // Themes end

        // Create chart instance
        am4core.options.commercialLicense = true;
        // am4core.options.autoDispose = true;

        var chart = am4core.create(this.containerRef, am4charts.XYChart);
        chart.paddingRight = 20;
        chart.background.fill = '#ffffff'
        
        // Add data
        this.newDataForChart = () => {
            chart.data = this.props.dataForChart

        }
        chart.data = this.props.dataForChart
        // chart.data = [{
        // "year": "1950",
        // "value": -0.307
        // }, {
        // "year": "1951",
        // "value": -0.168
        // }, {
        // "year": "1952",
        // "value": -0.073
        // }, {
        // "year": "1953",
        // "value": -0.027
        // }, {
        // "year": "1954",
        // "value": -0.251
        // }, {
        // "year": "1955",
        // "value": -0.281
        // }, {
        // "year": "1956",
        // "value": -0.348
        // }, {
        // "year": "1957",
        // "value": -0.074
        // }, {
        // "year": "1958",
        // "value": 0
        // }, {
        // "year": "1959",
        // "value": 0
        // }, {
        // "year": "1960",
        // "value": 0
        // }, {
        // "year": "1961",
        // "value": 0
        // }, {
        // "year": "1962",
        // "value": 0
        // }, {
        // "year": "1963",
        // "value": 0
        // }, {
        // "year": "1964",
        // "value": 0
        // }, {
        // "year": "1965",
        // "value": 0
        // }, {
        // "year": "1966",
        // "value": 0
        // }, {
        // "year": "1967",
        // "value": 0
        // }, {
        // "year": "1968",
        // "value": 0
        // }, {
        // "year": "1969",
        // "value": 0
        // }, {
        // "year": "1970",
        // "value": 0
        // }, {
        // "year": "1971",
        // "value": 0
        // }, {
        // "year": "1972",
        // "value": -0.056
        // }, {
        // "year": "1973",
        // "value": 0.077
        // }, {
        // "year": "1974",
        // "value": -0.213
        // }, {
        // "year": "1975",
        // "value": -0.17
        // }, {
        // "year": "1976",
        // "value": -0.254
        // }, {
        // "year": "1977",
        // "value": 0.019
        // }, {
        // "year": "1978",
        // "value": -0.063
        // }, {
        // "year": "1979",
        // "value": 0.05
        // }, {
        // "year": "1980",
        // "value": 0.077
        // }, {
        // "year": "1981",
        // "value": 0.12
        // }, {
        // "year": "1982",
        // "value": 0.011
        // }, {
        // "year": "1983",
        // "value": 0.177
        // }, {
        // "year": "1984",
        // "value": -0.021
        // }, {
        // "year": "1985",
        // "value": -0.037
        // }, {
        // "year": "1986",
        // "value": 0.03
        // }, {
        // "year": "1987",
        // "value": 0.179
        // }, {
        // "year": "1988",
        // "value": 0.18
        // }, {
        // "year": "1989",
        // "value": 0.104
        // }, {
        // "year": "1990",
        // "value": 0.255
        // }, {
        // "year": "1991",
        // "value": 0.21
        // }, {
        // "year": "1992",
        // "value": 0.065
        // }, {
        // "year": "1993",
        // "value": 0.11
        // }, {
        // "year": "1994",
        // "value": 0.172
        // }, {
        // "year": "1995",
        // "value": 0.269
        // }, {
        // "year": "1996",
        // "value": 0.141
        // }, {
        // "year": "1997",
        // "value": 0.353
        // }, {
        // "year": "1998",
        // "value": 0.548
        // }, {
        // "year": "1999",
        // "value": 0.298
        // }, {
        // "year": "2000",
        // "value": 0.267
        // }, {
        // "year": "2001",
        // "value": 0.411
        // }, {
        // "year": "2002",
        // "value": 0.462
        // }, {
        // "year": "2003",
        // "value": 0.47
        // }, {
        // "year": "2004",
        // "value": 0.445
        // }, {
        // "year": "2005",
        // "value": 0.47
        // }];

        
        // Create axes
        
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "year";
        // categoryAxis.renderer.minGridDistance = 50;
        // categoryAxis.renderer.grid.template.location = 0.5;
        // categoryAxis.startLocation = 0.5;
        // categoryAxis.endLocation = 0.5;
        categoryAxis.renderer.labels.template.fontSize = 12;
       

        // Create value axis
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeWidth = 0;
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.renderer.labels.template.fontWeight = 700;


        const{ insideStrokeColor }= this.props; 

        // Create series
        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "year";
        series.strokeWidth = 2;
        series.stroke= am4core.color(insideStrokeColor);
        series.fill = series.stroke;
        series.background.fill = am4core.color("#ffffff");
        series.tooltipText = "{valueY}";
        series.connect = false;
        series.minBulletDistance = 10;
        // series.stroke= am4core.color("grey");
        // series.tensionX = 0.77;

        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.tooltipText = "{valueY}";
        bullet.circle.strokeWidth = 2;
        bullet.circle.radius = 2;
        bullet.circle.fill = am4core.color("#fff");

        var bullethover = bullet.states.create("hover");
        bullethover.properties.scale = 1.5;

        // bullet is added because we add tooltip to a bullet for it to change color
        // var bullet = series.bullets.push(new am4charts.Bullet());
        // bullet.tooltipText = "{valueY}";

        // bullet.adapter.add("fill", function(fill, target){
        //     if(target.dataItem.valueY < lowerBound || target.dataItem.valueY > upperBound){
        //         return am4core.color(outsideStrokeColor);
        //     }
        //       return am4core.color(insideStrokeColor);
        // });


        // var range = valueAxis.createSeriesRange(series);
        // range.value = lowerBound;
        // range.endValue = upperBound;
        // range.contents.stroke = am4core.color(insideStrokeColor);
        // range.contents.fill = range.contents.stroke;

        chart.cursor = new am4charts.XYCursor();

        this.dispose = () => {
            chart.dispose();
            // am4core.disposeAllCharts();
        }
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.dataForChart !== prevProps.dataForChart &&  !this.props.temperature) {   
            this.newDataForChart();  
        }
        if(this.props.dataForChart !== prevProps.dataForChart && this.props.temperature){
            this.dispose()
            this.prepareChart()
        }
      }

    componentDidMount() {
        this.prepareChart();    
    }

    componentWillUnmount(){
        
    }
        render() {
            return (
                <div ref={this.setContainerRef} style={{ width: "100%", height: "200px", marginTop: "30px",borderRadius:"10px" }}></div>
            )
        }
}
    

 
export default LineChart;

